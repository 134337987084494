import { useCookies } from 'react-cookie'
import useAuth from './useAuth'
import useLogout from './useLogout'
import { createTokenRefreshCall } from '../api/token'

const useRefreshToken = () => {
    // Global
    const { auth, setAuth } = useAuth()
    const [cookie, setCookie] = useCookies()
    const logout = useLogout()

    // Refresh Token
    return async () => {
        try {
            const response = await createTokenRefreshCall(auth?.refreshToken || '')

            setAuth((prev) =>
                prev
                    ? {
                        ...prev,
                        token: response.token,
                        refreshToken: response.refresh_token,
                    }
                    : prev,
            )

            cookie.user.token = response.token
            cookie.user.refreshToken = response.refresh_token
            setCookie('user', cookie.user, { path: '/' })

            return response.token
        } catch {
            await logout()

            return null
        }
    }
}

export default useRefreshToken
