import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useAuth from './useAuth'
import { axiosAuth } from '../api/axios'

const useAxiosAuth = () => {
    // Global
    const refreshToken = useRefreshToken()
    const { auth } = useAuth()

    useEffect(() => {
        // Add Authorization all Request
        const requestIntercept = axiosAuth.interceptors.request.use(
            (config: any) => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.token}`
                }

                return config
            },
            (error: any) => Promise.reject(error),
        )

        // Check response
        const responseIntercept = axiosAuth.interceptors.response.use(
            (response: any) => response,
            async (error: any) => {
                const prevRequest = error?.config

                // Check Authorization url
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true

                    // Refresh Session
                    const newAccessToken = await refreshToken()
                    if (newAccessToken !== null) {
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`

                        return axiosAuth(prevRequest)
                    }
                }

                return Promise.reject(error)
            },
        )

        return () => {
            axiosAuth.interceptors.request.eject(requestIntercept)
            axiosAuth.interceptors.response.eject(responseIntercept)
        }
    }, [auth, refreshToken])

    return axiosAuth
}

export default useAxiosAuth
