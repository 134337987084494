import React, { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Locale from '../Locale'
import useAuth from '../../hooks/useAuth'

const LoginTemplate: FC<{ template: string, child: ReactNode }> = ({ template, child }) => {
    // Change status bar color
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#FFFFFF');

    // Global
    const navigate = useNavigate()
    const { auth } = useAuth()

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            if (auth.roles.includes('ROLE_PARENT')) {
                navigate('/activity')
            } else {
                navigate('/')
            }
        }
    }, [])

    return (
        <div className={`login-template d-flex min-vh-100 ${template}`}>
            <div className='login-header'>
                <img src={`/images/${template}/logo.svg`} alt='Logo'></img>
                <Locale />
            </div>

            <div className='container d-flex col-12 justify-content-center align-items-center flex-column'>
                {child}
            </div>
        </div>
    )
}

export default LoginTemplate
