import { PublicKeyCredentialCreationOptionsJSON, RegistrationResponseJSON } from '@simplewebauthn/types'
import { AxiosInstance, AxiosResponse } from 'axios'
import { axiosNotAuth } from './axios'

export interface UserRegisterInterface {
    id: number
    username: string
    last_name: string
    first_name: string
}

export const registerUserCall = async (
    username: string,
    lastName: string,
    firstName: string,
    password: string | null = null,
    sub: string | null = null,
    type: string | null = null,
): Promise<UserRegisterInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/user/register', {
        username: username,
        last_name: lastName,
        first_name: firstName,
        ...(password !== null ? { password: password } : {}),
        ...(sub !== null ? { sub: sub } : {}),
        ...(type !== null ? { type: type } : {}),
    })
    return response.data
}

export interface CreateUserPasswordTokenResetInterface {
    username: string
    hashed_token: string
}

export const createUserPasswordTokenCall = async (username: string): Promise<CreateUserPasswordTokenResetInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/user/password/token', {
        username: username,
    })
    return response.data
}

export interface CheckUserPasswordTokenInterface {
    username: string
    hashed_token: string
    token_expired_at: string
}

export const checkUserPasswordTokenCall = async (username: string, hashedToken: string): Promise<CheckUserPasswordTokenInterface> => {
    const response: AxiosResponse = await axiosNotAuth.get('/user/password/token', {
        params: {
            username: username,
            hashed_token: hashedToken,
        },
    })
    return response.data
}

export interface UserPasswordResetInterface {
    id: number
    username: string
}

export const resetUserPasswordCall = async (username: string, hashedToken: string, password: string): Promise<UserPasswordResetInterface> => {
    const response: AxiosResponse = await axiosNotAuth.put('/user/password/reset', {
        username: username,
        hashed_token: hashedToken,
        password: password,
    })
    return response.data
}

export interface CreateWebAuthnRegisterOptionsInterface {
    options: PublicKeyCredentialCreationOptionsJSON
}

export const createWebAuthnRegisterOptionCall = async (axiosAuth: AxiosInstance): Promise<CreateWebAuthnRegisterOptionsInterface> => {
    const response: AxiosResponse = await axiosAuth.post(
        '/user/webauthn/register/option',
        {},
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )
    return response.data
}

export interface CreateWebAuthnRegisterInterface {
    verified: boolean
}

export const createWebAuthnRegisterCall = async (
    axiosAuth: AxiosInstance,
    data: RegistrationResponseJSON,
    options: PublicKeyCredentialCreationOptionsJSON,
): Promise<CreateWebAuthnRegisterInterface> => {
    const response: AxiosResponse = await axiosAuth.post('/user/webauthn/register', {
        id: data.id,
        type: data.type,
        raw_id: data.rawId,
        authenticator_attachment: data.authenticatorAttachment,
        response: {
            attestation_object: data.response.attestationObject,
            authenticator_data: data.response.authenticatorData,
            client_data_json: data.response.clientDataJSON,
            public_key: data.response.publicKey,
            public_key_algorithm: data.response.publicKeyAlgorithm,
            transports: data.response.transports,
        },
        options: options,
    })
    return response.data
}

export interface CreateUserNotificationInterface {
    endpoint: string
}

export const createUserNotificationCall = async (
    axiosAuth: AxiosInstance,
    publicKey: string,
    authToken: string,
    endpoint: string,
): Promise<CreateUserNotificationInterface> => {
    const response: AxiosResponse = await axiosAuth.post('/user/notification', {
        public_key: publicKey,
        auth_token: authToken,
        endpoint: endpoint,
    })
    return response.data
}

export interface DisableUserNotificationInterface {
    endpoint: string
}

export const disableUserNotificationCall = async (
    axiosAuth: AxiosInstance,
    publicKey: string,
    authToken: string,
    endpoint: string,
): Promise<DisableUserNotificationInterface> => {
    const response: AxiosResponse = await axiosAuth.put('/user/notification/disable', {
        public_key: publicKey,
        auth_token: authToken,
        endpoint: endpoint,
    })
    return response.data
}
