import React, { FC } from 'react'
import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import ErrorTemplate from '../../components/Error/ErrorTemplate'

const NotFoundPage: FC = () => {
    // Global
    const { t }: { t: TFunction } = useTranslation('error')

    return (
        <div className='not-found-page'>
            <ErrorTemplate h1={t('error-page.not-found.h1')} />
        </div>
    )
}

export default NotFoundPage
