import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import ResetPassword from '../../components/Login/ResetPassword'

const ResetPasswordPage: FC<{ template: string }> = (template) => {
    return (
        <div className='reset-password-page'>
            <LoginTemplate template={template.template} child={<ResetPassword />} />
        </div>
    )
}

export default ResetPasswordPage
