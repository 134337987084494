import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import Register from '../../components/Login/Register'

const RegisterPage: FC<{ template: string }> = (template) => {
    return (
        <div className='register-page'>
            <LoginTemplate template={template.template} child={<Register template={template.template} />} />
        </div>
    )
}

export default RegisterPage
