import { Dispatch, SetStateAction } from 'react'
import { AxiosInstance } from 'axios'
import { createUserNotificationCall, disableUserNotificationCall } from '../api/user'
import { VAPID_PUBLIC_KEY } from '../config'

// Request Permission to Notification
export async function requestPermission(axiosAuth: AxiosInstance, setIsCheckedNotification: Dispatch<SetStateAction<boolean>>) {
    if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                registerNotification(axiosAuth)
                setIsCheckedNotification(true)
                localStorage.setItem('notification', 'true')
            } else if (permission === 'denied') {
                unregisterNotification(axiosAuth)
                setIsCheckedNotification(false)
                localStorage.setItem('notification', 'false')
            }
        })
    }
}

// Register
export async function registerNotification(axiosAuth: AxiosInstance) {
    try {
        if ('PushManager' in window) {
            const { endpoint, publicKey, authToken } = await getSubscriptionNavigator()
            await createUserNotificationCall(axiosAuth, publicKey, authToken, endpoint)
        }
    } catch (error) {
        console.error('Error User Notification register:', error)
    }
}

// Unregister
export async function unregisterNotification(axiosAuth: AxiosInstance) {
    try {
        if ('PushManager' in window) {
            const { endpoint, publicKey, authToken } = await getSubscriptionNavigator()
            await disableUserNotificationCall(axiosAuth, publicKey, authToken, endpoint)
        }
    } catch (error) {
        console.error('Error User Notification disable:', error)
    }
}

// Return Token Navigator
async function getSubscriptionNavigator() {
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
    })

    const p256dh = subscription.getKey('p256dh')
    const auth = subscription.getKey('auth')

    if (p256dh !== null && auth !== null) {
        const bufferP256dh = new Uint8Array(p256dh)
        const bufferAuth = new Uint8Array(auth)

        const endpoint = subscription.endpoint
        const publicKey = btoa(String.fromCharCode.apply(null, Array.from(bufferP256dh)))
        const authToken = btoa(String.fromCharCode.apply(null, Array.from(bufferAuth)))

        return { endpoint, publicKey, authToken }
    }

    return { endpoint: '', publicKey: '', authToken: '' }
}

// Encode
function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
}
