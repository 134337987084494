import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import InputField from '../InputField'
import useLoader from '../../hooks/useLoader'
import useMessage from '../../hooks/useMessage'
import { createUserPasswordTokenCall } from '../../api/user'

const ForgotPassword: FC = () => {
    // Global
    const { setLoader } = useLoader()
    const { setType, setMessage, setShow } = useMessage()
    const { t }: { t: TFunction } = useTranslation('login')
    const { t: tc }: { t: TFunction } = useTranslation('common')
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    // Form Refs
    const { ref: refUsername, ...inputPropsUsername } = register('username', {
        required: tc('form-error.required', { field: t('forgot-password-page.form.email.label') }),
        maxLength: {
            value: 50,
            message: tc('form-error.max-length', { field: t('forgot-password-page.form.email.label'), length: 50 }),
        },
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: tc('form-error.pattern', { field: t('forgot-password-page.form.email.label') }),
        },
    })

    // Call Forgot Password Submit
    const forgotPasswordSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoader(1)

        try {
            await createUserPasswordTokenCall(data.username)
            setMessage(t('forgot-password-page.form.success.message'))
            setType('success')
        } catch (error: any) {
            if (error.response.status === 404) {
                setMessage(t('forgot-password-page.form.error.user-not-found.message'))
            } else {
                setMessage(t('forgot-password-page.form.error.password-not-found.message'))
            }
            setType('error')
        }

        setShow(true)
        setLoader(0)
    }

    return (
        <div className='login-template-form p-4 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='login-template-subtext text-center fw-bold text-uppercase pb-3'>{t('forgot-password-page.h1')}</h1>
            <form className='d-flex flex-column w-100' onSubmit={handleSubmit(forgotPasswordSubmit)}>
                <InputField
                    label={t('forgot-password-page.form.email.label')}
                    className='login-template-field'
                    error={!!errors.username}
                    helper={errors?.username?.message?.toString()}
                    inputRef={refUsername}
                    inputProps={inputPropsUsername}
                ></InputField>
                <button className='login-template-submit' type='submit'>
                    {t('forgot-password-page.form.submit.label')}
                </button>
            </form>
            <div className='text-center pt-2'>
                <Link to='/login'>{t('forgot-password-page.back.link')}</Link>
            </div>
        </div>
    )
}

export default ForgotPassword
