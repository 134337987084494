import React, { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { createTokenCall, getTokenSocialRedirectCall } from '../../api/token'
import { registerUserCall } from '../../api/user'
import useLoader from '../../hooks/useLoader'
import useMessage from '../../hooks/useMessage'
import useAuth from '../../hooks/useAuth'
import { loginUser } from '../../helperFunctions/loginUser'
import InputField from '../InputField'

const Register: FC<{ template: string }> = (template) => {
    // Global
    const navigate = useNavigate()
    const { setType, setMessage, setShow } = useMessage()
    const { setAuth } = useAuth()
    const [, setCookie] = useCookies()
    const { setLoader } = useLoader()
    const { t }: { t: TFunction } = useTranslation('login')
    const { t: tc }: { t: TFunction } = useTranslation('common')
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    // Form refs
    const { ref: refLastName, ...inputPropsLastName } = register('lastName', {
        required: tc('form-error.required', { field: t('register-email-page.form.last-name.label') }),
        maxLength: {
            value: 50,
            message: tc('form-error.max-length', { field: t('register-email-page.form.last-name.label'), length: 50 }),
        },
    })
    const { ref: refFirstName, ...inputPropsFirstName } = register('firstName', {
        required: tc('form-error.required', { field: t('register-email-page.form.first-name.label') }),
        maxLength: {
            value: 50,
            message: tc('form-error.max-length', { field: t('register-email-page.form.first-name.label'), length: 50 }),
        },
    })
    const { ref: refUsername, ...inputPropsUsername } = register('username', {
        required: tc('form-error.required', { field: t('register-email-page.form.email.label') }),
        maxLength: {
            value: 50,
            message: tc('form-error.max-length', { field: t('register-email-page.form.email.label'), length: 50 }),
        },
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: tc('form-error.pattern', { field: t('register-email-page.form.email.label') }),
        },
    })
    const { ref: refPassword, ...inputPropsPassword } = register('password', {
        required: tc('form-error.required', { field: t('register-email-page.form.password.label') }),
        minLength: {
            value: 8,
            message: tc('form-error.min-length', { field: t('register-email-page.form.password.label'), length: 8 }),
        },
    })
    const { ref: refRepeatPassword, ...inputPropsRepeatPassword } = register('repeatPassword', {
        validate: (value) => value === watch('password') || tc('form-error.identical', { field: t('register-email-page.form.password.label') }),
    })

    // Register User Submit
    const registerUserSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoader(1)

        try {
            await registerUserCall(data.username, data.lastName, data.firstName, data.password)
            const response = await createTokenCall(data.username, data.password)
            loginUser(response.token, response.refresh_token, setAuth, setCookie, navigate)
            setMessage(t('register-email-page.form.success.message'))
            setType('success')
        } catch (error: any) {
            if (error.response.status === 409) {
                setMessage(t('register-email-page.form.error.user-already-exist.message'))
            } else {
                setMessage(t('register-email-page.form.error.invalid-credential.message'))
                navigate('/login')
            }
            setType('error')
        }

        setShow(true)
        setLoader(0)
    }

    if( template ){
        console.log('yolo')
    }

    // Redirect URL to social Register
    const registerSocialClick = async (social: string) => {
        setLoader(1)

        try {
            const response = await getTokenSocialRedirectCall(social, 'register')
            window.location.href = response.redirect_url
        } catch {
            navigate('/500')
        }

        setLoader(0)
    }

    return (
        <div className='login-template-form p-4 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='login-template-subtext text-center fw-bold text-uppercase pb-3'>{t('register-page.h1')}</h1>
            <form className='d-flex flex-column w-100' onSubmit={handleSubmit(registerUserSubmit)}>
                <InputField
                    label={t('register-email-page.form.last-name.label')}
                    className='login-template-field'
                    error={!!errors.lastName}
                    helper={errors?.lastName?.message?.toString()}
                    inputRef={refLastName}
                    inputProps={inputPropsLastName}
                ></InputField>
                <InputField
                    label={t('register-email-page.form.first-name.label')}
                    className='login-template-field'
                    error={!!errors.firstName}
                    helper={errors?.firstName?.message?.toString()}
                    inputRef={refFirstName}
                    inputProps={inputPropsFirstName}
                ></InputField>
                <InputField
                    label={t('register-email-page.form.email.label')}
                    type='email'
                    className='login-template-field'
                    error={!!errors.username}
                    helper={errors?.username?.message?.toString()}
                    inputRef={refUsername}
                    inputProps={inputPropsUsername}
                ></InputField>
                <InputField
                    label={t('register-email-page.form.password.label')}
                    type='password'
                    className='login-template-field'
                    error={!!errors.password}
                    helper={errors?.password?.message?.toString()}
                    inputRef={refPassword}
                    inputProps={inputPropsPassword}
                ></InputField>
                <InputField
                    label={t('register-email-page.form.password-confirm.label')}
                    type='password'
                    className='login-template-field'
                    error={!!errors.repeatPassword}
                    helper={errors?.repeatPassword?.message?.toString()}
                    inputRef={refRepeatPassword}
                    inputProps={inputPropsRepeatPassword}
                ></InputField>
                <button className='login-template-submit' type='submit'>
                    {t('register-email-page.form.submit.label')}
                </button>
            </form>
            <div className='d-flex flex-wrap justify-content-center pt-3'>
                <button className='login-template-submit google' onClick={() => registerSocialClick('google')}>
                    <span>
                        <i className='fa-brands fa-google me-3'></i>
                        {t('register-page.button.google.label')}
                    </span>
                </button>
                <button className='login-template-submit facebook' onClick={() => registerSocialClick('facebook')}>
                    <span>
                        <i className='fa-brands fa-facebook me-3'></i>
                        {t('register-page.button.facebook.label')}
                    </span>
                </button>
                <button className='login-template-submit apple' onClick={() => registerSocialClick('apple')}>
                    <span>
                        <i className='fa-brands fa-apple me-3'></i>
                        {t('register-page.button.apple.label')}
                    </span>
                </button>
            </div>
            <hr className='w-100' />
            <span className='pt-3'>
                {t('register-page.login.label')}
                <Link to='/login' className='text-decoration-underline'>
                    {t('register-page.login.link')}
                </Link>
            </span>
        </div>
    )
}

export default Register
