import { AxiosInstance, AxiosResponse } from 'axios'

export interface getActorInterface {
    id: number
    avatar: string
    lastName: string
    firstName: string
}

interface getActorsInterface {
    actors: getActorInterface[]
}

// Todo get Company id
export const getStaffActorsCall = async (axiosAuth: AxiosInstance): Promise<getActorsInterface> => {
    const response: AxiosResponse = await axiosAuth.get('/actors/company', {
        params: {
            company_id: 1,
        },
    })
    return response.data
}
