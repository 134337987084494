import i18next from 'i18next'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from './config'
import common_fr from './translations/fr/common.json'
import common_en from './translations/en/common.json'
import error_fr from './translations/fr/error.json'
import error_en from './translations/en/error.json'
import login_fr from './translations/fr/login.json'
import login_en from './translations/en/login.json'
import logout_fr from './translations/fr/logout.json'
import logout_en from './translations/en/logout.json'

const navigatorLanguage: string = new Intl.Locale(navigator.language).language || DEFAULT_LOCALE
const language: string = SUPPORTED_LOCALES.includes(navigatorLanguage) ? navigatorLanguage : DEFAULT_LOCALE
const locale: string = localStorage.getItem('locale') || language

i18next.init({
    interpolation: { escapeValue: false },
    lng: locale,
    resources: {
        fr: {
            common: common_fr,
            error: error_fr,
            login: login_fr,
            logout: logout_fr,
        },
        en: {
            common: common_en,
            error: error_en,
            login: login_en,
            logout: logout_en,
        },
    },
})

localStorage.setItem('locale', locale)

export default i18next
