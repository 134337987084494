import React, { FC } from 'react'
import './styles/index.scss'
import Routing from './Routing'
import Loader from './components/Loader'
import Message from './components/Message'
import Modal from './components/Modal'

const App: FC = () => {
    return (
        <>
            <Routing />
            <Loader />
            <Message />
            <Modal />
        </>
    )
}

export default App
