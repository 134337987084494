import React from 'react'
import ReactDOM, { Root } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'bootstrap/dist/js/bootstrap.min.js'
import i18next from './Translation'
import App from './App'
import { AuthProvider } from './contexts/AuthProvider'
import { LoaderProvider } from './contexts/LoaderProvider'
import { MessageProvider } from './contexts/MessageProvider'
import { ModalProvider } from './contexts/ModalProvider'

// Application
const root: Root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <AuthProvider>
                <LoaderProvider>
                    <MessageProvider>
                        <ModalProvider>
                            <App />
                        </ModalProvider>
                    </MessageProvider>
                </LoaderProvider>
            </AuthProvider>
        </BrowserRouter>
    </I18nextProvider>,
)

serviceWorkerRegistration.register()
