import { AxiosResponse } from 'axios'
import { axiosNotAuth } from './axios'
import { AuthenticationResponseJSON, PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/types'

export interface TokenInterface {
    token: string
    refresh_token: string
}

export const createTokenCall = async (username: string, password: string): Promise<TokenInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/token', {
        username: username,
        password: password,
    })
    return response.data
}

export const createTokenRefreshCall = async (refreshToken: string): Promise<TokenInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/token/refresh', {
        refresh_token: refreshToken,
    })
    return response.data
}

export const createTokenSocialCall = async (token: string, social: string): Promise<TokenInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/token/social', {
        token: token,
        social: social,
    })
    return response.data
}

export const createWebAuthnRequestCall = async (
    data: AuthenticationResponseJSON,
    options: PublicKeyCredentialRequestOptionsJSON,
): Promise<TokenInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/token/webauthn/request', {
        id: data.id,
        type: data.type,
        raw_id: data.rawId,
        authenticator_attachment: data.authenticatorAttachment,
        response: {
            authenticator_data: data.response.authenticatorData,
            client_data_json: data.response.clientDataJSON,
            signature: data.response.signature,
            user_handle: data.response.userHandle,
        },
        options: options,
    })
    return response.data
}

export interface TokenInvalidateInterface {
    message: string
}

export const tokenInvalidateCall = async (refreshToken: string): Promise<TokenInvalidateInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post('/token/invalidate', {
        refresh_token: refreshToken,
    })
    return response.data
}

export interface TokenSocialRedirectInterface {
    redirect_url: string
}

export const getTokenSocialRedirectCall = async (social: string, page: string): Promise<TokenSocialRedirectInterface> => {
    const response: AxiosResponse = await axiosNotAuth.get('/token/social/redirect', {
        params: {
            social: social,
            page: page,
        },
    })
    return response.data
}

export interface TokenSocialInterface {
    access_token: string
    user_exist: boolean
    user_social_exist: boolean
}

export const getTokenSocialCall = async (code: string, state: string, scope: string, social: string, page: string): Promise<TokenSocialInterface> => {
    const response: AxiosResponse = await axiosNotAuth.get('/token/social', {
        params: {
            code: code,
            state: state,
            scope: scope,
            social: social,
            page: page,
        },
    })
    return response.data
}

export interface CreateWebAuthnRequestOptionsInterface {
    options: PublicKeyCredentialRequestOptionsJSON
}

export const createWebAuthnRequestOptionCall = async (): Promise<CreateWebAuthnRequestOptionsInterface> => {
    const response: AxiosResponse = await axiosNotAuth.post(
        '/token/webauthn/request/option',
        {},
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )
    return response.data
}
