import axios, { AxiosInstance } from 'axios'
import { API_BASE_URL } from '../config'

// Call Not Bearer Auth
export const axiosNotAuth: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

// Call Bearer Auth
export const axiosAuth: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
})

export interface UserCookieInterface {
    username: string
    lastName: string
    firstName: string
    roles: string[]
    token: string
    refreshToken: string
}

export interface DecodeTokenOauthInterface {
    sub: string
    email: string
    family_name: string
    given_name: string
}

export interface DecodeTokenInterface {
    username: string
    last_name: string
    first_name: string
    roles: string[]
}
