import React, { FC } from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../config'
import useLoader from '../hooks/useLoader'

const Locale: FC = () => {
    const { t }: { t: TFunction } = useTranslation('common')
    const localeActive = localStorage.getItem('locale') || DEFAULT_LOCALE
    const { loader, setLoader } = useLoader()

    // Change Locale
    const changeLocale = (locale: string) => {
        setLoader(loader + 1)
        localStorage.setItem('locale', locale)
        window.location.reload()
    }

    if (SUPPORTED_LOCALES.length > 1) {
        return (
            <div className='position-absolute top-0 end-0'>
                <div className='dropdown'>
                    <button className='btn' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                        <i className={`fi fi-${localeActive}`}></i>
                    </button>
                    <ul className='dropdown-menu'>
                        {SUPPORTED_LOCALES.map(
                            (locale) =>
                                locale !== localeActive && (
                                    <li className='text-center' key={locale} onClick={() => changeLocale(locale)}>
                                        <i className={`dropdown-item fi fi-${locale}`}></i>
                                        <span className='ms-2'>{t(`locale.${locale}.label`)}</span>
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Locale
