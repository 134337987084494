import React, { FC, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import InputField from '../InputField'
import useLoader from '../../hooks/useLoader'
import useMessage from '../../hooks/useMessage'
import { checkUserPasswordTokenCall, resetUserPasswordCall } from '../../api/user'

const ResetPassword: FC = () => {
    // Global
    const navigate = useNavigate()
    const { email, token } = useParams()
    const { setLoader } = useLoader()
    const { setType, setMessage, setShow } = useMessage()
    const { t }: { t: TFunction } = useTranslation('login')
    const { t: tc }: { t: TFunction } = useTranslation('common')
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    // Form refs
    const { ref: refPassword, ...inputPropsPassword } = register('password', {
        required: tc('form-error.required', { field: t('reset-password-page.form.password.label') }),
        minLength: {
            value: 8,
            message: tc('form-error.min-length', { field: t('reset-password-page.form.password.label'), length: 8 }),
        },
    })
    const { ref: refRepeatPassword, ...inputPropsRepeatPassword } = register('repeatPassword', {
        validate: (value) => value === watch('password') || tc('form-error.identical', { field: t('reset-password-page.form.password.label') }),
    })

    // Call Reset User Password Submit
    const resetUserPasswordSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoader(1)

        try {
            await resetUserPasswordCall(email || '', token || '', data.password)
            setMessage(t('reset-password-page.form.success.message'))
            setType('success')
            navigate('/login')
        } catch (error: any) {
            if (error.response.status === 404) {
                if (error.response.data.code === 'USER_NOT_FOUND') {
                    setMessage(t('reset-password-page.form.error.user-not-found.message'))
                } else {
                    setMessage(t('reset-password-page.form.error.password-token-not-found.message'))
                }
            } else {
                if (error.response.data.code === 'USER_PASSWORD_TOKEN_EXPIRED') {
                    setMessage(t('reset-password-page.form.error.password-token-expired.message'))
                } else {
                    setMessage(t('reset-password-page.form.error.password-not-found.message'))
                }
            }
            setType('error')
        }

        setShow(true)
        setLoader(0)
    }

    // Check Password Token Load
    const checkPasswordTokenLoad = async () => {
        setLoader(1)

        try {
            await checkUserPasswordTokenCall(email || '', token || '')
        } catch (error: any) {
            if (error.response.status === 404) {
                if (error.response.data.code === 'USER_NOT_FOUND') {
                    setMessage(t('reset-password-page.form.error.user-not-found.message'))
                } else {
                    setMessage(t('reset-password-page.form.error.password-token-not-found.message'))
                }
            } else {
                setMessage(t('reset-password-page.form.error.password-token-expired.message'))
            }
            setType('error')
            setShow(true)
            navigate('/login')
        }

        setLoader(0)
    }

    useEffect(() => {
        checkPasswordTokenLoad()
    }, [])

    return (
        <div className='login-template-form p-4 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='login-template-subtext text-center fw-bold text-uppercase pb-3'>{t('reset-password-page.h1')}</h1>
            <form className='d-flex flex-column w-100' onSubmit={handleSubmit(resetUserPasswordSubmit)}>
                <InputField
                    label={t('reset-password-page.form.password.label')}
                    type='password'
                    className='login-template-field'
                    error={!!errors.password}
                    helper={errors?.password?.message?.toString()}
                    inputRef={refPassword}
                    inputProps={inputPropsPassword}
                ></InputField>
                <InputField
                    label={t('reset-password-page.form.password-confirm.label')}
                    type='password'
                    className='login-template-field'
                    error={!!errors.repeatPassword}
                    helper={errors?.repeatPassword?.message?.toString()}
                    inputRef={refRepeatPassword}
                    inputProps={inputPropsRepeatPassword}
                ></InputField>
                <button className='login-template-submit' type='submit'>
                    {t('reset-password-page.form.submit.label')}
                </button>
            </form>
            <div className='text-center pt-2'>
                <Link to='/login'>{t('reset-password-page.back.link')}</Link>
            </div>
        </div>
    )
}

export default ResetPassword
