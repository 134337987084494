import React, { FC, Ref } from 'react'
import TextField from '@mui/material/TextField'
import { TextFieldPropsSizeOverrides, TextFieldVariants } from '@mui/material/TextField/TextField'
import { OverridableStringUnion } from '@mui/types'
import { InputLabelProps } from '@mui/material/InputLabel/InputLabel'

// Input Special MUI
const InputField: FC<{
    label: string
    className?: string
    type?: string
    required?: boolean
    variant?: TextFieldVariants
    color?: string
    backgroundColor?: string
    borderColor?: string
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>
    margin?: 'dense' | 'normal' | 'none'
    error?: boolean
    helper?: string
    forceShrink?: boolean
    inputRef?: Ref<any>
    inputProps?: Record<string, any>
}> = ({
    label,
    className = '',
    type = 'text',
    variant = 'filled',
    color = '#000',
    backgroundColor = '#fff',
    borderColor = '#000',
    size = 'small',
    margin = 'none',
    error = false,
    helper = null,
    forceShrink = false,
    inputRef = null,
    inputProps = null,
}) => {
    const labelProps: InputLabelProps = {
        sx: {
            color: color,
        },
    }

    // Force Label to shrink if setValue Form
    if (forceShrink) {
        labelProps.shrink = true
    }

    return (
        <TextField
            label={label}
            type={type}
            className={className}
            variant={variant}
            size={size}
            margin={margin}
            sx={{
                'label.Mui-focused': { color: color },
                '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: borderColor, color: borderColor },
                '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: borderColor },
                '.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': { borderColor: borderColor },
                '.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': { borderColor: borderColor },
                '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: borderColor, color: color },
                '.MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': { borderColor: borderColor },
                '.MuiOutlinedInput-root.Mui-focused:active .MuiOutlinedInput-notchedOutline': { borderColor: borderColor },
                '.MuiOutlinedInput-root.Mui-focused:focus .MuiOutlinedInput-notchedOutline': { borderColor: borderColor },
                '.MuiInput-underline:after, .MuiInput-underline:before': { borderBottomColor: borderColor },
                '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before, .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after': {
                    borderBottom: '1px solid ' + borderColor,
                },
                '.MuiInput-root.Mui-focused:hover:before, .MuiInput-root.Mui-focused:hover:after': { borderBottom: '2px solid ' + borderColor },
                '.MuiFilledInput-underline:after, .MuiFilledInput-underline:before': { border: 'none' },
                '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
                '.MuiFilledInput-root': {
                    background: backgroundColor,
                    border: '1px solid ' + borderColor,
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                },
                '.MuiFilledInput-root.Mui-focused': {
                    background: backgroundColor,
                    border: '2px solid ' + borderColor,
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                },
                '.MuiFilledInput-root:hover, .MuiFilledInput-root.Mui-focused:hover': { background: backgroundColor },
                '.MuiFilledInput-root:active, .MuiFilledInput-root.Mui-focused:active': { background: backgroundColor },
                '.MuiFilledInput-root:focus, .MuiFilledInput-root.Mui-focused:focus': { background: backgroundColor },
                'label.Mui-focused.Mui-error': { color: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-error:active .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-error:focus .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error:active .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error:focus .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiInput-root.Mui-error.Mui-focused:hover:before, .MuiInput-root.Mui-error.Mui-focused:hover:after': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-error': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-error:hover': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-error:active': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-error:focus': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-focused.Mui-error': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-focused.Mui-error:hover': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-focused.Mui-error:active': { borderColor: '#d32f2f' },
                '.MuiFilledInput-root.Mui-focused.Mui-error:focus': { borderColor: '#d32f2f' },
            }}
            InputLabelProps={labelProps}
            inputProps={{
                sx: {
                    color: color,
                },
            }}
            error={error}
            helperText={helper}
            inputRef={inputRef}
            {...inputProps}
        />
    )
}

export default InputField
