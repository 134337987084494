import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import Login from '../../components/Login/Login'

const LoginPage: FC<{ template: string }> = (template) => {
    return (
        <div className='login-page'>
            <LoginTemplate template={template.template} child={<Login template={template.template} />} />
        </div>
    )
}

export default LoginPage
