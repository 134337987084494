import React, { Context, createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { useCookies } from 'react-cookie'
import { UserCookieInterface } from '../api/axios'

// Context Authentication
interface AuthContextType {
    auth: UserCookieInterface | null
    setAuth: Dispatch<SetStateAction<UserCookieInterface | null>>
}
const AuthContext: Context<AuthContextType> = createContext<AuthContextType>({
    auth: null,
    setAuth: (value) => value,
})

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [cookie] = useCookies(['user'])
    const userCookie: UserCookieInterface | null = cookie?.user
    let dataAuth = null

    if (null !== userCookie) {
        // Data Cookie
        dataAuth = {
            username: userCookie?.username,
            lastName: userCookie?.lastName,
            firstName: userCookie?.firstName,
            roles: userCookie?.roles,
            token: userCookie?.token,
            refreshToken: userCookie?.refreshToken,
        }
    }

    // Init Context Auth (Reload Auth Provider with Cookie)
    const [auth, setAuth] = useState<UserCookieInterface | null>(dataAuth)

    return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthContext
