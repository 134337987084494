import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { jwtDecode } from 'jwt-decode'
import useAuth from '../../hooks/useAuth'
import useLoader from '../../hooks/useLoader'
import useMessage from '../../hooks/useMessage'
import { DecodeTokenOauthInterface } from '../../api/axios'
import { createTokenSocialCall, getTokenSocialCall } from '../../api/token'
import { registerUserCall } from '../../api/user'
import { loginUser } from '../../helperFunctions/loginUser'

const RegisterCallBackPage: FC = () => {
    // Global
    const navigate = useNavigate()
    const { setLoader } = useLoader()
    const { setType, setMessage, setShow } = useMessage()
    const { auth, setAuth } = useAuth()
    const { social = '' } = useParams()
    const { t }: { t: TFunction } = useTranslation('login')
    const [, setCookie] = useCookies()

    // Get Token Load
    const getTokenLoad = async () => {
        setLoader(1)

        const params = new URLSearchParams(window.location.search)
        const code = params.get('code') || ''
        const state = params.get('state') || ''
        const scope = params.get('scope') || ''

        try {
            const socialU = social.charAt(0).toUpperCase() + social.slice(1)
            const response = await getTokenSocialCall(code, state, scope, socialU, 'register')
            if (!response.user_exist) {
                const decode: DecodeTokenOauthInterface = jwtDecode(response.access_token)
                await registerUserCall(decode.email, decode.family_name, decode.given_name, null, decode.sub, socialU)
                const responseToken = await createTokenSocialCall(response.access_token, socialU)
                loginUser(responseToken.token, responseToken.refresh_token, setAuth, setCookie, navigate)
            } else {
                setMessage(t('login-callback-page.error.user-already-exist.message'))
                setType('error')
                setShow(true)
                navigate('/register')
            }
        } catch (error: any) {
            if (error.response.status === 404) {
                setMessage(t('login-callback-page.error.user-not-found.message'))
            } else if (error.response.status === 409) {
                setMessage(t('login-callback-page.error.user-already-exist.message'))
            } else {
                setMessage(t('login-callback-page.error.token-problem.message'))
            }
            setType('error')
            setShow(true)
            navigate('/register')
        }

        setLoader(0)
    }

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            if (auth.roles.includes('ROLE_PARENT')) {
                navigate('/activity')
            } else {
                navigate('/')
            }
        } else {
            getTokenLoad()
        }
    }, [])

    return null
}

export default RegisterCallBackPage
