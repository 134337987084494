import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { type TFunction } from 'i18next'

const ErrorTemplate: FC<{ h1: string }> = ({ h1 }) => {
    // Global
    const navigate = useNavigate()
    const { t }: { t: TFunction } = useTranslation('error')

    // Back Page
    const back = () => {
        navigate(-1)
    }

    return (
        <div className='error-template d-flex min-vh-100'>
            <div className='container d-flex col-12 justify-content-center align-items-center flex-column'>
                <h1>{h1}</h1>
                <button className='btn btn-primary' onClick={back}>
                    {t('error-page.template.button.label')}
                </button>
            </div>
        </div>
    )
}

export default ErrorTemplate
