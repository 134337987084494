import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFoundPage from './pages/Error/NotFoundPage'
import ServerErrorPage from './pages/Error/ServerErrorPage'
import UnauthorizedPage from './pages/Error/UnauthorizedPage'
import HomePage from './pages/HomePage'
import RequireAuth from './components/RequireAuth'
import LoginPage from './pages/Login/LoginPage'
import RegisterPage from './pages/Login/RegisterPage'
import ForgotPasswordPage from './pages/Login/ForgotPasswordPage'
import ResetPasswordPage from './pages/Login/ResetPasswordPage'
import LoginCallBackPage from './pages/Login/LoginCallBack'
import RegisterCallBackPage from './pages/Login/RegisterCallBack'
import ActorActivityPage from './pages/Representative/ActorActivityPage'
import ActorListingyPage from './pages/Staff/ActorListingPage'

const Routing: FC = () => {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path='/login' element={<LoginPage template='simply-it' />}></Route>
            <Route path='/login/1' element={<LoginPage template='vanille-choco' />}></Route>
            <Route path='/login/2' element={<LoginPage template='petits-chaperons-rouges' />}></Route>
            <Route path='/login/3' element={<LoginPage template='patte-zen-cinq' />}></Route>
            <Route path='/login/4' element={<LoginPage template='domus' />}></Route>
            <Route path='/login/5' element={<LoginPage template='pokecaverne' />}></Route>
            <Route path='/register' element={<RegisterPage template='simply-it' />}></Route>
            <Route path='/forgot-password' element={<ForgotPasswordPage template='simply-it' />}></Route>
            <Route path='/reset-password/:email/:token' element={<ResetPasswordPage template='simply-it' />}></Route>
            <Route path='/login/:social/auth/callback' element={<LoginCallBackPage />}></Route>
            <Route path='/register/:social/auth/callback' element={<RegisterCallBackPage />}></Route>

            {/* Routes Connected */}
            <Route element={<RequireAuth allowedRoles={['ROLE_API']} />}>
                <Route path='/' element={<HomePage />}></Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={['ROLE_PARENT']} />}>
                <Route path='/activity/:actorId?/:currentDate?' element={<ActorActivityPage />}></Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={['ROLE_STAFF']} />}>
                <Route path='/staff' element={<ActorListingyPage />}></Route>
            </Route>

            {/* Routes Error */}
            <Route path='/unauthorized' element={<UnauthorizedPage />} />
            <Route path='/500' element={<ServerErrorPage />} />
            <Route path='/*' element={<NotFoundPage />} />
        </Routes>
    )
}

export default Routing
