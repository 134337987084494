import { NavigateFunction } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { DecodeTokenInterface } from '../api/axios'

export function loginUser(token: string, refreshToken: string, setAuth: any, setCookie: any, navigate: NavigateFunction) {
    // Data
    const decode: DecodeTokenInterface = jwtDecode(token)
    const username = decode.username
    const lastName = decode.last_name
    const firstName = decode.first_name
    const roles = decode.roles

    if (roles.includes('ROLE_PARENT') || roles.includes('ROLE_API')) {
        let path = '/'
        if (roles.includes('ROLE_PARENT')) {
            path = '/activity'
        }

        if (roles.includes('ROLE_STAFF')) {
            path = '/staff'
        }

        setAuth({ username, lastName, firstName, roles, token, refreshToken })
        setCookie('user', { username, lastName, firstName, roles, token, refreshToken }, { path: '/' })
        navigate(path)
    } else {
        navigate('/unauthorized')
    }
}
