import React, { FC, useEffect, useState } from 'react'
import Locale from '../../components/Locale'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import useAxiosAuth from '../../hooks/useAxiosAuth'
import { getActorInterface, getActivityInterface, getRepresentativeActorsCall, getActorActivityCall } from '../../api/representative'
import { Link, useParams, useNavigate } from 'react-router-dom'
import useLoader from '../../hooks/useLoader'
import useLogout from '../../hooks/useLogout'
import useModal from '../../hooks/useModal'

const ActorActivityPage: FC = () => {
    // Global
    const { setLoader } = useLoader()
    const { setTitle, setShow, setButtons } = useModal()
    const logout = useLogout()
    const [display, setDisplay] = useState(false)
    const [displayActivities, setDisplayActivities] = useState(false)
    const [activeActor, setActiveActor] = useState<getActorInterface>()
    const [currentDate, setCurrentDate] = useState<string>('')
    const [actors, setActors] = useState<getActorInterface[]>()
    const [activities, setActivities] = useState<getActivityInterface[]>()
    const axiosAuth = useAxiosAuth()
    const navigate = useNavigate()
    const params = useParams()
    const requestActorId = params?.actorId ? parseInt(params?.actorId) : 0
    const requestCurrentDate = params?.currentDate ? params?.currentDate : new Date().toISOString().slice(0, 10)
    const selectedDate: HTMLInputElement | null = document.querySelector('#selectedDate')
    const { t }: { t: TFunction } = useTranslation('logout')

    const getActors = async () => {
        let activeActorId = null
        const response = await getRepresentativeActorsCall(axiosAuth)
        setActors(response.actors)

        if (0 === requestActorId && undefined != response.actors) {
            setActiveActor(response.actors[0])
            activeActorId = response.actors[0].id
        } else {
            setActiveActor(response.actors.find((actor) => actor.id == requestActorId))
            activeActorId = requestActorId
        }

        setDisplay(true)

        return activeActorId
    }

    const getActorActivity = async (activeActorId: number, currentDate: string) => {
        const response = await getActorActivityCall(axiosAuth, activeActorId, currentDate)
        setActivities(response.activities)
        setDisplayActivities(true)
    }

    useEffect(() => {
        setCurrentDate(requestCurrentDate)
        getActors().then((activeActorId) => {
            getActorActivity(activeActorId, currentDate)
        })
    }, [requestActorId, requestCurrentDate])

    function displayCalendar() {
        if (null != selectedDate) {
            selectedDate.showPicker()
        }
    }

    function changeCurrentDate() {
        let activeActorId = null

        if (undefined != activeActor) {
            activeActorId = activeActor.id
        }

        if (null != selectedDate) {
            navigate(`/activity/${activeActorId}/${selectedDate.value}`)
        }
    }

    const logoutProcess = async () => {
        setLoader(1)
        setShow(false)
        await logout()
        setLoader(0)
    }

    const reset = () => {
        setShow(false)
    }

    const showLogoutModal = () => {
        setTitle(t('logout.modal.title'))
        setShow(true)
        setButtons([
            {icon: <i className="fa-solid fa-car"></i>, label: 'Annuler', onClick: () => reset(), color:'info'},
            {label: 'Valider', onClick: () => logoutProcess(), color:'error'},
        ])
    }

    // Logout Action
    const logoutAction = async (confirm = true) => {
        if( confirm ){
            showLogoutModal()
        }else{
            await logoutProcess()
        }
    }

    return (
        <div className='home-page d-flex min-vh-100'>
            <Locale />
            <div className='container d-flex col-12 align-items-center flex-column'>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '5px' }}>
                    <span>
                        <img style={{ width: '20px' }} src='/images/logo.png' alt='Logo'></img>
                    </span>

                    <span style={{ marginLeft: '5px' }}>Ma Tiote App</span>

                    <i role='presentation' onClick={() => logoutAction()} className="fa-solid fa-power-off" />
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', margin: '10px 0', flexWrap: 'wrap' }}>
                    {display &&
                        undefined != actors &&
                        actors.map((actor, key) => (
                            <Link key={key} to={`/activity/${actor.id}/${currentDate}`}>
                                <div key={key}>
                                    <img
                                        className={`child-avatar ${actor.id == activeActor?.id ? 'child-avatar-active' : ''}`}
                                        src={`/images/${actor.avatar}`}
                                        alt={actor.firstName}
                                    ></img>
                                </div>
                                <div className='child-badge child-badge-bottom-right'>1</div>
                            </Link>
                        ))}
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '10px' }}>
                    {display && (
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            <span>
                                {new Date(currentDate).toLocaleDateString('fr-FR', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                            </span>
                            <span>
                                <img
                                    role='presentation'
                                    onClick={displayCalendar}
                                    src='/images/calendar.png'
                                    alt='Calendrier'
                                    style={{ width: '40px' }}
                                ></img>
                            </span>
                            <input onChange={changeCurrentDate} id='selectedDate' type='date' style={{ display: 'none' }}></input>
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', marginTop: '10px' }}>
                    {displayActivities &&
                        undefined != activities &&
                        activities.length > 0 &&
                        activities.map((activity, key) => (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    marginBottom: '10px',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    borderRadius: '5px',
                                    padding: '5px',
                                    boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
                                }}
                                key={key}
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <img style={{ width: '40px', borderRadius: '20px', padding: '5px' }} src={`/images/${activity.type}.png`}></img>
                                </div>
                                <div>
                                    {`${String(new Date(activity.datetime).getUTCHours()).padStart(2, '0')}:${String(new Date(activity.datetime).getMinutes()).padStart(2, '0')}`}{' '}
                                    - {activity.name}
                                </div>
                            </div>
                        ))}

                    {displayActivities && undefined != activities && activities.length == 0 && (
                        <>
                            <div>{activeActor?.firstName} a t-elle bien dormi ?</div>
                            <div>
                                <i className='fa-solid fa-moon'></i> <input type='time'></input>
                            </div>
                            <div>
                                <i className='fa-solid fa-sun'></i> <input type='time'></input>
                            </div>

                            <div>{activeActor?.firstName} a t-elle bien mangé ?</div>
                            <div>
                                <i className='fa-solid fa-utensils'></i>
                            </div>

                            <div>{activeActor?.firstName} repartira de la crèche avec l&apos;un de ses parents ?</div>
                            <div>
                                <i className='fa-solid fa-person-walking-arrow-right'></i>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ActorActivityPage
