import { AxiosInstance, AxiosResponse } from 'axios'

export interface getActorInterface {
    id: number
    avatar: string
    lastName: string
    firstName: string
}

interface getActorsInterface {
    actors: getActorInterface[]
}

export const getRepresentativeActorsCall = async (axiosAuth: AxiosInstance): Promise<getActorsInterface> => {
    const response: AxiosResponse = await axiosAuth.get('/actors/representative')
    return response.data
}

export interface getActivityInterface {
    id: number
    name: string
    type: string
    value: string
    datetime: string
    comments: string
}
interface getActivitiesInterface {
    activities: getActivityInterface[]
}

export const getActorActivityCall = async (axiosAuth: AxiosInstance, actorId: number, currentDate: string): Promise<getActivitiesInterface> => {
    const response: AxiosResponse = await axiosAuth.get(`/actor/${actorId}/activity`, {
        params: {
            date: currentDate,
        },
    })
    return response.data
}
