import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import ForgotPassword from '../../components/Login/ForgotPassword'

const ForgotPasswordPage: FC<{ template: string }> = (template) => {
    return (
        <div className='forgot-password-page'>
            <LoginTemplate template={template.template} child={<ForgotPassword />} />
        </div>
    )
}

export default ForgotPasswordPage
