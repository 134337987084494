import React, { Context, createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'

// Context Loader
interface LoaderContextType {
    loader: number
    setLoader: Dispatch<SetStateAction<number>>
}
const LoaderContext: Context<LoaderContextType> = createContext<LoaderContextType>({
    loader: 0,
    setLoader: (value) => value,
})

export const LoaderProvider: FC<{ children: ReactNode }> = ({ children }) => {
    // Init Context Loader
    const [loader, setLoader] = useState<number>(0)

    return <LoaderContext.Provider value={{ loader, setLoader }}>{children}</LoaderContext.Provider>
}

export default LoaderContext
