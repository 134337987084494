import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { startRegistration } from '@simplewebauthn/browser'
import { Switch } from '@mui/material'
import Locale from '../components/Locale'
import useLogout from '../hooks/useLogout'
import useLoader from '../hooks/useLoader'
import useAxiosAuth from '../hooks/useAxiosAuth'
import useMessage from '../hooks/useMessage'
import { createWebAuthnRegisterCall, createWebAuthnRegisterOptionCall } from '../api/user'
import { requestPermission, unregisterNotification } from '../helperFunctions/notificationPush'

const HomePage: FC = () => {
    // Global
    const { t }: { t: TFunction } = useTranslation('common')
    const { setLoader } = useLoader()
    const { setType, setMessage, setShow } = useMessage()
    const logout = useLogout()
    const axiosAuth = useAxiosAuth()
    const [isCheckedBiometric, setIsCheckedBiometric] = useState(false)
    const [isCheckedNotification, setIsCheckedNotification] = useState(false)

    // Biometric Change
    const biometricChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoader(1)

        setIsCheckedBiometric(event.target.checked)
        if (event.target.checked) {
            await registerBiometricClick()
        } else {
            localStorage.setItem('biometric', 'false')
        }

        setLoader(0)
    }

    // Notification Change
    const notificationChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoader(1)

        if (event.target.checked) {
            await requestPermission(axiosAuth, setIsCheckedNotification)
        } else {
            await unregisterNotification(axiosAuth)
            setIsCheckedNotification(false)
            localStorage.setItem('notification', 'false')
        }

        setLoader(0)
    }

    // Register Biometric Click
    const registerBiometricClick = async () => {
        try {
            const response = await createWebAuthnRegisterOptionCall(axiosAuth)
            const biometric = await startRegistration(response.options)

            await createWebAuthnRegisterCall(axiosAuth, biometric, response.options)

            setMessage(t('user-setting-page.biometric.success.message'))
            setType('success')
            localStorage.setItem('biometric', 'true')
        } catch (e: any) {
            if (e.name === 'InvalidStateError') {
                localStorage.setItem('biometric', 'true')
                setMessage(t('home-page.biometric.success.message'))
                setType('success')
            } else {
                if (e.name !== 'NotAllowedError') {
                    setMessage(t('home-page.biometric.error.problem.message'))
                    setType('error')
                }
                localStorage.setItem('biometric', 'false')
                setIsCheckedBiometric(false)
            }
        }
        setShow(true)
    }

    // Logout Click
    const logoutClick = async () => {
        setLoader(1)
        await logout()
        setLoader(0)
    }

    useEffect(() => {
        if (localStorage.getItem('biometric') === 'true') {
            setIsCheckedBiometric(true)
        }
        if (localStorage.getItem('notification') === 'true') {
            setIsCheckedNotification(true)
        }
    }, [])

    return (
        <div className='home-page d-flex min-vh-100'>
            <Locale />
            <div className='container d-flex col-12 justify-content-center align-items-center flex-column'>
                <span className='text-center'>
                    <img className='logo w-50' alt='Logo' src='/images/logo.png'></img>
                </span>
                <div className='home-page-middle p-4 d-flex justify-content-center align-items-center flex-column'>
                    <h1 className='home-page-subtext text-center fw-bold text-uppercase pb-3'>{t('home-page.h1')}</h1>
                    <p>{t('home-page.text')}</p>
                    <div className='user-setting-item d-flex align-items-center justify-content-between'>
                        <span>{t('home-page.button.biometric.label')}</span>
                        <Switch checked={isCheckedBiometric} onChange={biometricChange} />
                    </div>
                    <div className='user-setting-item d-flex align-items-center justify-content-between'>
                        <span>{t('home-page.button.notification.label')}</span>
                        <Switch checked={isCheckedNotification} onChange={notificationChange} />
                    </div>
                    <button className='home-page-button' onClick={() => logoutClick()}>
                        {t('home-page.button.logout.label')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HomePage
