import React, { FC, useEffect, useState } from 'react'
import Locale from '../../components/Locale'
import useAxiosAuth from '../../hooks/useAxiosAuth'
import { getActorInterface, getStaffActorsCall } from '../../api/staff'
import { Link } from 'react-router-dom'

const ActorListingyPage: FC = () => {
    // Global
    const [display, setDisplay] = useState(false)
    const [actors, setActors] = useState<getActorInterface[]>()
    const axiosAuth = useAxiosAuth()

    const getActors = async () => {
        const response = await getStaffActorsCall(axiosAuth)
        setActors(response.actors)
        setDisplay(true)
    }

    useEffect(() => {
        getActors()
    }, [])

    return (
        <div className='home-page d-flex min-vh-100'>
            <Locale />
            <div className='container d-flex col-12 align-items-center flex-column'>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '5px' }}>
                    <span>
                        <img style={{ width: '20px' }} src='/images/logo.png' alt='Logo'></img>
                    </span>

                    <span style={{ marginLeft: '5px' }}>Ma Tiote App</span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', margin: '10px 0', flexWrap: 'wrap' }}>
                    {display &&
                        undefined != actors &&
                        actors.map((actor, key) => (
                            <Link key={key} to={'/'}>
                                <div key={key}>
                                    <img className='child-avatar child-avatar-active' src={`/images/${actor.avatar}`} alt={actor.firstName}></img>
                                </div>
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default ActorListingyPage
