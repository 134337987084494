import React, { Context, createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { AlertColor } from '@mui/material/Alert/Alert'

// Context Message
interface MessageContextType {
    message: string | null
    setMessage: Dispatch<SetStateAction<string | null>>
    type: AlertColor
    setType: Dispatch<SetStateAction<AlertColor>>
    duration: number
    setDuration: Dispatch<SetStateAction<number>>
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
}

const MessageContext: Context<MessageContextType> = createContext<MessageContextType>({
    message: null,
    setMessage: (value) => value,
    type: 'success',
    setType: (value) => value,
    duration: 3000,
    setDuration: (value) => value,
    show: false,
    setShow: (value) => value,
})

export const MessageProvider: FC<{ children: ReactNode }> = ({ children }) => {
    // Init Context Message
    const [message, setMessage] = useState<string | null>(null)
    const [type, setType] = useState<AlertColor>('success')
    const [duration, setDuration] = useState<number>(3000)
    const [show, setShow] = useState<boolean>(false)

    return (
        <MessageContext.Provider value={{ message, setMessage, type, setType, duration, setDuration, show, setShow }}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContext
