import React, { Context, createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { ButtonPropsColorOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'

// Context Modal
interface ModalButton {
    icon?: JSX.Element | null
    label: string
    color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>
    onClick?: () => void | null | Promise<void>
}
interface ModalContextType {
    title: string | null
    setTitle: Dispatch<SetStateAction<string | null>>
    content: string | null
    setContent: Dispatch<SetStateAction<string | null>>
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
    buttons: ModalButton[] | null
    setButtons: Dispatch<SetStateAction<ModalButton[] | null>>
    closeButton: boolean
    setCloseButton: Dispatch<SetStateAction<boolean>>
}

const ModalContext: Context<ModalContextType> = createContext<ModalContextType>({
    title: null,
    setTitle: (value) => value,
    content: null,
    setContent: (value) => value,
    show: false,
    setShow: (value) => value,
    buttons: null,
    setButtons: (value) => value,
    closeButton: true,
    setCloseButton: (value) => value,
})

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
    // Init Context Modal
    const [title, setTitle] = useState<string | null>(null)
    const [content, setContent] = useState<string | null>(null)
    const [show, setShow] = useState<boolean>(false)
    const [buttons, setButtons] = useState<ModalButton[] | null>(null)
    const [closeButton, setCloseButton] = useState<boolean>(true)

    return (
        <ModalContext.Provider value={{ title, setTitle, content, setContent, show, setShow, buttons, setButtons, closeButton, setCloseButton }}>{children}</ModalContext.Provider>
    )
}

export default ModalContext
