import { useCookies } from 'react-cookie'
import useAuth from './useAuth'
import { tokenInvalidateCall } from '../api/token'

const useLogout = () => {
    // Global
    const { auth, setAuth } = useAuth()
    const [, , removeCookie] = useCookies(['user'])

    // Logout
    return async () => {
        await tokenInvalidateCall(auth?.refreshToken || '')

        setAuth(null)
        removeCookie('user', { path: '/' })
    }
}

export default useLogout
