import React, { FC } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import useLoader from '../hooks/useLoader'

const Loader: FC = () => {
    // Global
    const { loader } = useLoader()

    return (
        <>
            <Backdrop sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader >= 1}>
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    )
}

export default Loader
