import React, { FC, forwardRef, Ref } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import useMessage from '../hooks/useMessage'

const Alert = forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
Alert.displayName = 'Alert'

const Message: FC = () => {
    // Global
    const { show, setShow, duration, type, message } = useMessage()

    // Close Message
    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setShow(false)
    }

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={show} autoHideDuration={duration} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '90%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Message
